import React, {Component, Fragment} from "react";
import axios from "axios";
import Clock from "./components/Clock"
import HTTPClient from "./HTTPClient";
import {getMessage} from "./util";

const dateFormat = require("dateformat");


const NoRC = (props) => {

    return(
        <div className="row justify-content-center mt-4">
            <div className="col-10">
                <div className="alert alert-warning"><h5>{getMessage('intermitencia_rc', props.messages, props.language)}</h5></div>
            </div>
        </div>

    )
}

class Document extends Component{

    renderSection(sectionNumber) {
        const keyTitle = `title_section${sectionNumber}`

        return (
            <Fragment>
                <div className="row justify-content-center">
                    {this.props.language === "en"
                        ?
                        <div className="w-100" key={sectionNumber}>
                            <div className="listCandidates_title EvColorBlue text-white p-2 my-2 text-center">
                                <h4 className="listCandidates-title">
                                    {getMessage(`${keyTitle}`, this.props.messages, this.props.language)}
                                </h4>
                            </div>
                            <div className="row mx-2 justify-content-center align-content-center align-items-center">
                                {this.props.election.configuration.documents.en
                                    .filter(f => f.section === sectionNumber)
                                    .map(f => (
                                        <div key={f.url}
                                             className="listCandidates-content col-4 my-2 d-flex justify-content-center align-content-center">
                                            <a href={f.url} target="_blank" rel="noopener noreferrer">{f.name}</a>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        :
                        <div className="w-100" key={sectionNumber}>
                            <div className="listCandidates_title EvColorBlue text-white p-2 my-2 text-center">
                                <h4 className="listCandidates-title">
                                    {getMessage(`${keyTitle}`, this.props.messages, this.props.language)}
                                </h4>
                            </div>
                            <div className="row mx-2 justify-content-center align-content-center align-items-center">
                                {this.props.election.configuration.documents.default
                                    .filter(f => f.section === sectionNumber)
                                    .map(f => (
                                        <div key={f.url}
                                             className="listCandidates-content col-4 my-2 d-flex justify-content-center align-content-center">
                                            <a href={f.url} target="_blank" rel="noopener noreferrer">{f.name}</a>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    }
            </div>
    </Fragment>
    )
        ;
    }

    render() {

        return (
            <Fragment>
                <div className="listCandidates-container">
                    {this.renderSection(1)}
                    {this.renderSection(2)}
                    {this.renderSection(3)}
                    {this.renderSection(4)}
                </div>
            </Fragment>
        )
    }
}


class ButtonElection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clock: '',
            state: 'none',
            finished: '',
            rc: []
        };
    }

    componentDidMount() {

        const end_date = new Date(this.props.election.configuration.end_date).getTime();
        const start_date = new Date(this.props.election.configuration.start_date).getTime();
        const self = this;
        this.loadConfig();


        // verifica cada 30 segundos situación en el RC
        setInterval(function () {
            self.loadRC();
        }, 30000);

        setInterval(async function () {

            const got_time = await HTTPClient({
                method: "get",
                url:
                    process.env.REACT_APP_API_URL +
                    "/now"
            });

            const now = new Date(got_time.data);
            let distance = start_date - now;
            let state = 'ready';
            if (start_date && end_date) {
                if (now >= start_date && now < end_date) {

                    distance = end_date - now;
                    state = 'open';
                } else if (now >= end_date) {
                    state = 'finished';
                }
            } else {
                state = 'noDate';
            }


            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            self.setState({
                state: state,
                clock: days + (days !== 1 ? " días " : " día ") + (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes) + ':' + (seconds < 10 ? ('0' + seconds) : seconds)
            });
        }, 1000);
    }

    loadConfig = name => {
        this.setState({loading: true}, () => {
            axios({
                method: "get",
                //url: "/data/prueba.json"
                url: process.env.REACT_APP_API_URL + "/api/election/" + this.props.election._id
            })
                .then(response => {
                    this.setState({loading: false, finished: response.data.state});
                })
                .catch(error => {


                    this.setState({loading: false});
                })
        });
    };

    loadRC = name => {
        this.setState({loading: true}, () => {
            axios({
                method: "get",
                //url: "/data/demo.evoting.cl.json"
                url: process.env.REACT_APP_BASE_URL + "/rc.json"
            })
                .then(response => {

                    this.setState({loading: false, rc: response.data});
                })
                .catch(error => {
                    this.setState({loading: false});
                })
        });
    };

    render() {

        let button = null;

        switch (this.state.state) {
            case 'open':
                button = <Fragment>
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11  text-center  my-m4 ">
                            {this.props.language === "en"
                                ?
                                <a className="boton EvColor"
                                   href={this.props.election.configuration.encrypter_url.replace(/%s/g, this.props.election._id)+"/?lang=en"}><span
                                    className="iconvote"></span>{getMessage('boton_votar', this.props.messages, this.props.language)}
                                </a>
                                :
                                <a className="boton EvColor"
                                   href={this.props.election.configuration.encrypter_url.replace(/%s/g, this.props.election._id)}><span
                                    className="iconvote"></span>{getMessage('boton_votar', this.props.messages, this.props.language)}
                                </a>

                            }

                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11  text-center  my-m4">
                            <div className="finaliza">
                                <p>{getMessage('finaliza', this.props.messages, this.props.language)} {dateFormat(new Date(this.props.election.configuration.end_date), "dd '/' mm '/' yyyy '"+getMessage('conector_fecha', this.props.messages, this.props.language)+"' HH'.'MM 'hrs.'")}</p>
                                {/* <p>{this.state.clock}</p> */}
                                <Clock messages={this.props.messages} election={this.props.election} date={this.props.election.configuration.end_date} language={this.props.language}/>
                            </div>
                        </div>
                    </div>
                </Fragment>;
                break;
            case 'finished':
                button =
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11  text-center  my-m4 mb-0">
                            <p className="h3 font-weight-bold">{getMessage('finalizada', this.props.messages, this.props.language)} {dateFormat(new Date(this.props.election.configuration.end_date), "dd '/' mm '/' yyyy '"+getMessage('conector_fecha', this.props.messages, this.props.language)+"' HH'.'MM 'hrs.'")}</p>
                        </div>
                        <div className="col-12 col-sm-11  text-center  my-m4 ">
                            <a className="boton resultados EvColor" href={this.props.election.configuration.bulletin_url.replace(/%s/g, this.props.election._id)}>{(this.state.finished==="finished")?(getMessage('resultados', this.props.messages, this.props.language)):(getMessage('participacion', this.props.messages, this.props.language))}</a>
                        </div>
                    </div>;
                break;
            case 'ready':
                button = <Fragment>
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11  text-center my-m4 ">
                            <botton className="boton EvColorGris ready" href="#">
                                <span className="iconvote"></span>
                                {getMessage('boton_votar', this.props.messages, this.props.language)}
                                <span>{getMessage('etiqueta_boton', this.props.messages, this.props.language)}</span>
                            </botton>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11 text-center">
                            <div className="finaliza">
                                <p>{getMessage('comienza', this.props.messages, this.props.language)} {dateFormat(new Date(this.props.election.configuration.start_date), "dd '/' mm '/' yyyy '"+getMessage('conector_fecha', this.props.messages, this.props.language)+"' HH'.'MM 'hrs.'")}</p>
                                {/* <p>{this.state.clock}</p> */}
                                <Clock messages={this.props.messages} election={this.props.election} date={this.props.election.configuration.start_date} language={this.props.language}/>
                            </div>
                        </div>
                    </div>
                </Fragment>;
                break;
            default:
                button = <div className="row justify-content-center">
                    <div className="col-12 col-sm-11  text-center  my-m4">
                    </div>
                </div>;
                break;
        }

        const [field, pass] = this.props.election.configuration.identification_field.split('+', 2);
        const serial = (pass === 'serial' || pass === 'serial_mobile');
        //console.log(this.state.rc)
        const nowTime = new Date();

        const rc_intermittence = this.state.rc?this.state.rc.reduce((acc, element) => {
            return (acc || (new Date(element.start) < nowTime && new Date(element.end) > nowTime));
        }, false):false;


        // Si estamos en un rango con intermitencia y está abierta y usa serial
        return (
            <Fragment>
                {(rc_intermittence && this.state.state === 'open' && serial) &&
                    <NoRC messages={this.props.messages} language={this.props.language}/>}
                {button}
                <div className=" w-100">
                    <div
                        className="row justify-content-center listCandidates_title EvColor text-white p-2 my-2 text-center">
                        <h4 className="listCandidates-title">
                            {getMessage("title_document", this.props.messages, this.props.language)}
                        </h4>
                    </div>
                </div>
                {this.props.election.configuration.document ?
                    <Document election={this.props.election} messages={this.props.messages}
                              language={this.props.language}/> : false}
            </Fragment>);
    }
}

export default ButtonElection;