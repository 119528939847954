import React, { Fragment } from "react";
import './Election.css';
import Dudas from "./Dudas";
import SaberMas from "./SaberMas";
import Encabezado from "./Encabezado";
import ComoVotar from "./ComoVotar";
import ButtonElection from "./ButtonElection";
import Video from "./Video";

const Election = (props) => {
    const linkVideo = props.election.configuration.video_url;

    return (
        <Fragment>
            <Encabezado election={props.election} language={props.language} messages={props.election.messages} />
            <ButtonElection messages={props.election.messages} election={props.election} language={props.language} />
            <ComoVotar messages={props.election.messages} election={props.election} language={props.language} />
            <Dudas messages={props.election.messages} election={props.election} language={props.language} />
            <SaberMas messages={props.election.messages} election={props.election} language={props.language} />
            {linkVideo === "" ? null : <Video video_url={props.election.configuration.video_url} />}
        </Fragment>
    );
}

export default Election;
