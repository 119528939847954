import React from "react";
import ojo_img from "./images/ojo.svg";
import cuaderno_img from "./images/cuaderno.svg";
import {getMessage} from "./util";

const SaberMas =(props)=> {

        return (
            <div className="row justify-content-center sabermas EvColorBlue text-white">
                <div className="col-10 text-center mt-4 sabermas-box">
                    <h3 className="font-weight-bold">
                        {getMessage("saber_mas_titulo_1", props.messages, props.language)}
                    </h3>
                    <div>
                        <span className="linespanwhite mb-4"></span>
                    </div>
                    <div className="col-12">
                        <a href={props.election.configuration.bulletin_url.replace(/%s/g, props.election._id)}><img
                            src={ojo_img} className="img-max-100" alt="Participación"/>
                            <p className="font-weight-bold no-decoration">{getMessage("participacion_1", props.messages, props.language)}
                                <br/> {getMessage("participacion_2", props.messages, props.language)} </p>
                        </a>
                    </div>
                </div>
            </div>

        );
    }


export default SaberMas;