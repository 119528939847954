import React, { Fragment} from "react";
import logomin_img from"./images/logomin.svg";
import {getMessage} from "./util";

const version = require('../package.json');

const Footer =(props)=> {

        return (
            <Fragment>
                <div className="row justify-content-center EvColorBlue mesa-ayuda">
                    <div className="mesa-ayuda-content row col-12 my-4 mx-0 justify-content-center EvColorBlue align-items-center">
                        <div className="my-6 p-0 text-white text-right">
                            <img src={logomin_img} className="img-max-100 mesa-ayuda-img" alt="footer"/>
                        </div>
                        <div className="col-6 col-lg-6 col-md-6 col-sm-6 my-2 mx-3 text-white font-weight-bold d-flex row justify-content-center">
                            <p className="col-12 text-center">{getMessage("tittle_footer", props.messages, props.language)}</p>
                            <div className="mesa-ayuda-text text-center row justify-content-center">
                                <p
                                    className="mesa-ayuda-text d-flex m-0 mesa-ayuda-text text-center justify-content-center align-items-center">
                                    {getMessage("correo_soporte", props.messages, props.language)}
                                </p>
                                <div className="m-0 mx-4 justify-content-end">
                                    <a className="mesa-ayuda-text btn-wsp btn btn-white" target="_blank"
                                       href="https://api.whatsapp.com/send?phone=56985574361&text=Hola,%20necesito%20ayuda%20en%20la%20Votaci%C3%B3n%20del%20Comit%C3%A9%20de%20Elecciones%202024-2026%20OEA">
                                        {getMessage("soporte_wsp", props.messages, props.language)}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-right">
                        {/*<small>© EVoting {new Date().getFullYear()}, v. {version.version}</small>*/}
                        <small>{getMessage("footer", props.messages, props.language)}</small>
                    </div>
                </div>

            </Fragment>

        );

}


export default Footer;