import React, { Fragment} from "react";
import {getMessage} from "./util";

const Encabezado =(props)=> {
    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-11  text-center m-4 ">
                    <img
                        className="img-fluid logo"
                        alt="Logo"
                        src={props.election.configuration.logo_url}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-11 text-center">
                    {props.language === "en"
                        ?
                        <>
                            <h2 className="font-weight-bold">{getMessage('election_name', props.messages, props.language)}</h2>
                            <h3 className="font-italic">{getMessage('organization_name', props.messages, props.language)}</h3>
                            <h5>{getMessage('texto_horario', props.messages, props.language)}</h5>

                        </>
                        :
                        <>
                            <h2 className="font-weight-bold">{getMessage('election_name', props.messages, props.language)}</h2>
                            <h3 className="font-italic">{getMessage('organization_name', props.messages, props.language)}</h3>
                            <h5>{getMessage('texto_horario', props.messages, props.language)}</h5>
                        </>
                    }


                </div>
            </div>
        </Fragment>
    )
}

export default Encabezado;